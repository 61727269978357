body {
  text-align: center;
}

h1 {
  color: #ff69b4;
  font-family: cursive;
  font-size: 55px;
}

p {
  font-family: Helvetica, Arial, sans-serif;
  line-height: 1.5em;
}

a {
  color: #1e90ff;
}

* {
  margin: 0;
  padding: 0;
}

html, body, #barcode {
  width: 100vw;
}

/*# sourceMappingURL=index.1f6b4cf6.css.map */
